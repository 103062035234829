import React from 'react'

const Eligibility = () => {
  return (<>
         <div className='Main-eligibility'>
            <div id='eligibility' className='eligibility-heading'>
                <h1>Eligibility</h1>
            </div>
            <div className='criteria'>
                <ul>
                    <li>
                        All professional Institutes Like Medical, Engineering, Management, B.Ed., Computer Applications etc.
                    </li>
                    <li>
                        Any Graduate/ Post Graduate College.
                    </li>
                    <li>
                        Organizations (Govt./Semi Govt./Banks)
                    </li>
                    <li>
                        All Boys and Girls Schools.
                    </li>
                    <li>
                        Entry for Individual events opens to Men & Women team members and their extra players.
                    </li>
                    <li>
                        Individual entries are accepted for individual events. Age rules will be followed as per norms of
                        TTFI/UPTTA.
                    </li>
                    <li>
                        Commencement of Particular Event will depend on number of Entries.
                    </li>
                    <li>
                        Entries are open for all States.
                    </li>
                    <li>
                        Men and Mixed Double can be made in open basis (Not restricted to same institution). No Age criteria
                    </li>
                </ul>
            </div>
        </div>
    </>)
}

export default Eligibility
